import { useEffect } from 'react'

function useCalculateScrollbarWidth() {
  useEffect(() => {
    const calc = () => {
      const width = window.innerWidth - document.body.clientWidth;
      const inexplicableExtraSpace = 8;
      const actualScrollbarWidth = width > 0 ? width - inexplicableExtraSpace : 0;
      document.documentElement.style.setProperty('--scrollbar-width', `${actualScrollbarWidth}px`);
    };
    // Calculate on component mount and window resize
    calc();
    window.addEventListener('resize', calc);
    return () => window.removeEventListener('resize', calc);
  }, []);
}

export default useCalculateScrollbarWidth;
