import { Col, Row } from 'antd';
import SSLIcon from 'assets/img/SSL.png';
import clsx from 'clsx';
import B2BecLink from 'components/B2BLink';
import usePermission from 'hooks/usePermission';
import { MODULE_SERVICE_RIGHTS } from 'libs/constants/modulerights';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { parseHtml } from '../../../../libs/utils/common';
import styles from './Footer.module.scss';

const currentYear = new Date().getFullYear();

// Hide Footer when in these pages
// to prevent the Footer jump to top of the page
const excludeFooterPages = [
  '/exchange-token',
  '/callback',
  '/logout',
  '/admin-login',
  '/technical-login',
];

function Footer() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { hasPermission } = usePermission(
    MODULE_SERVICE_RIGHTS.VIEW_CONTACT_FORM
  );

  if (excludeFooterPages.includes(pathname)) return null;

  const isInExcludedFooterPage = excludeFooterPages.some((excludeFooterPage) =>
    pathname?.endsWith(excludeFooterPage)
  );

  if (isInExcludedFooterPage) {
    return null;
  }

  const parsedServiceInformation = hasPermission
    ? parseHtml(t('footer.serviceInformation'))
    : parseHtml(t('footer.serviceInformation')).splice(0, 3);

  return (
    <footer className={styles.container}>
      <div className={styles['business-workspace']}>
        <span>{t('welcome')}</span>
        <span>{t('welcome-subtext')}</span>
      </div>
      <Row className={styles.top__wrapper}>
        <Col
          xl={{ span: 7, offset: 2, order: 0 }}
          lg={{ span: 7, offset: 2, order: 0 }}
          md={{ span: 8, offset: 0, order: 0 }}
          sm={12}
          xs={24}
          className={styles.col_wrapper}
        >
          <h3>{t('footer.contact')}</h3>
          <p>{t('footer.contactAddress')}</p>
        </Col>
        <Col
          xl={{ span: 8, order: 0 }}
          lg={{ span: 8, order: 0 }}
          md={{ span: 10, order: 0 }}
          sm={{ span: 12, order: 3 }}
          xs={{ span: 24, order: 3 }}
          className={styles.col_wrapper}
        >
          <h3>{t('footer.serviceHotline')}</h3>
          <p>{parsedServiceInformation}</p>
        </Col>
        <Col
          xl={{ span: 7, order: 0 }}
          lg={{ span: 7, order: 0 }}
          md={{ span: 6, order: 0 }}
          sm={{ span: 12, order: 5 }}
          xs={{ span: 24, order: 5 }}
          className={styles.col_wrapper}
        >
          <img
            title="SSL Secured"
            alt="SSL Secured"
            src={SSLIcon}
            height={70}
            width={51}
            loading="lazy"
          />
        </Col>
        <Col
          xl={{ span: 7, offset: 2, order: 0 }}
          lg={{ span: 7, offset: 2, order: 0 }}
          md={{ span: 8, offset: 0, order: 0 }}
          sm={12}
          xs={24}
          className={styles.col_wrapper}
        >
          <h3>{t('footer.legal')}</h3>
          <p>
            <B2BecLink to="/general-terms-and-conditions">
              {t('footer.AGBKBusinessPortal')}
            </B2BecLink>
          </p>
          <p>
            <B2BecLink to="/imprint">{t('footer.imprint')}</B2BecLink>
          </p>
          <p>
            <B2BecLink to="/data-protection-policy">
              {t('footer.dataProtection')}
            </B2BecLink>
          </p>
        </Col>
        <Col
          xl={{ span: 15, order: 0 }}
          lg={{ span: 15, order: 0 }}
          md={{ span: 16, order: 0 }}
          sm={{ span: 12, order: 4 }}
          xs={{ span: 24, order: 4 }}
          className={styles.col_wrapper}
        >
          <h3>{t('footer.repairService')}</h3>
          <p>{parseHtml(t('footer.repairServiceContact'))}</p>
        </Col>
      </Row>
      <Row className={clsx(styles.bottom__wrapper)}>
        <h3>{t('footer.copyright', { currentYear })}</h3>
      </Row>
    </footer>
  );
}

export default Footer;
