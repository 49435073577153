import './styles.scss';

import { Col, Divider, Row } from 'antd';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useDeviceDetect from 'hooks/useDeviceDetect';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { PAGE_TYPES, TRACK_DATA } from 'libs/constants/adobeAnalytics';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import {
  selectIsFetchingUserDetails,
  selectUserFirstName,
  selectUserLastName,
  selectUserSalutationLowerCase,
} from 'store/selectors/userSelector';

import PermissionWrapper from '../../HOCs/permissionWrapper';
import {
  MODULE_MACHINE_RIGHTS,
  MODULE_SHOP_NAVIGATION_RIGHTS,
} from '../../libs/constants/modulerights';
import MyMachines from './MyMachines';
import OverviewGuide from './OverviewGuide';
import ShopByCategories from './ShopByCategories';
import SpecialsForYou from './SpecialsForYou';
import useCalculateScrollbarWidth from 'hooks/useCalculateScrollbarWidth';

const Greeting = () => {
  const { t } = useTranslation();
  const isFetchingUserDetails = useSelector(selectIsFetchingUserDetails);
  const firstName = useSelector(selectUserFirstName);
  const lastName = useSelector(selectUserLastName);
  const lowerCaseSalutation = useSelector(selectUserSalutationLowerCase);
  const title = t(`form.label.${lowerCaseSalutation}`, { defaultValue: '' });
  const titleAndName = t('myWorkspace.greeting.titleAndName', {
    title,
    firstName,
    lastName,
  });

  if (isFetchingUserDetails) {
    return <Skeleton width={400} height={33} />;
  }
  return (
    <span>{t('myWorkspace.greeting.salutation', { name: titleAndName })}</span>
  );
};

const MyWorkspace = ({ title, pageId }) => {
  useCalculateScrollbarWidth();
  useDocumentTitle(title);
  useAdobeAnalysis(TRACK_DATA.HOME, {
    pageName: 'home',
    pageId,
    pageType: PAGE_TYPES.STANDARD,
  });

  const { isIpadLandscape } = useDeviceDetect();

  return (
    <div className="my-workspace__wrapper">
      <div className="my-workspace__welcome-banner">
        <Greeting />
      </div>
      {!isIpadLandscape && <div className="break-line__30px" />}

      <div className="container-full-width-page-workspace my-workspace__my-machines__wrapper">
        <div className="my-workspace__my-machines__wrapper__inner">
          <ShopByCategories />
          <Divider style={{ marginTop: 0 }} />
          <OverviewGuide />
        </div>
      </div>
      <PermissionWrapper
        permission={MODULE_SHOP_NAVIGATION_RIGHTS.VIEW_PROMOTION}
      >
        <div className="my-workspace__specials-for-you-section">
          <SpecialsForYou />
        </div>
      </PermissionWrapper>

      <PermissionWrapper permission={MODULE_MACHINE_RIGHTS.VIEW_MACHINE_LIST}>
        <Row gutter={24}>
          <Col span={24}>
            <div className="my-workspace__my-machines__container">
              <MyMachines />
            </div>
          </Col>
        </Row>
      </PermissionWrapper>
    </div>
  );
};

MyWorkspace.propTypes = {
  title: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
};

export default MyWorkspace;
