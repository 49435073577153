import './i18n';
import 'antd/dist/antd.css';
import './App.scss';

import { Amplify } from 'aws-amplify';
import B2becSpinner from 'components/B2becSpinner';
import ErrorBoundary from 'components/ErrorBoundary';
import environment from 'environments/environment.dev';
import ExternalFileScripts from 'ExternalFileScripts';
import { SESSION_BASED_EXPIRY_TIME } from 'libs/constants';
import { setCookie } from 'libs/utils/cookies';
import { history } from 'libs/utils/history';
import { hasAllPropertiesValid } from 'libs/utils/object';
import { getInfoFromUrl } from 'libs/utils/punchoutHandler';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router as ReactRouter } from 'react-router-dom';

import App from './App';
import store from './store';

const {
  aws: { cognito },
} = environment;

Amplify.configure({
  Auth: {
    region: cognito.region,
    userPoolId: cognito.userPoolId,
    userPoolWebClientId: cognito.clientId,
    oauth: {
      domain: cognito.oauthDomain,
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: `${window.location.origin}/callback`,
      redirectSignOut: `${window.location.origin}/logout`,
      responseType: 'code',
    },
  },
});

const getPunchoutData = () => {
  const punchoutTypes = ['oci', 'procurement'];
  const { pathname = '', href = '' } = window?.location;
  const isPunchout = pathname.includes('punchout');
  const pathParts = href.split('/');

  const punchoutType = punchoutTypes.find((type) => {
    return pathParts.includes(type);
  });

  if (!isPunchout || pathParts.includes('technical-login')) {
    return {};
  }

  const queryKeys = ['accessKey', 'secret', 'HOOK_URL', 'sessionId'];
  if (punchoutType === 'oci') {
    const [encodedSecrets, queryString] =
      pathParts[pathParts.length - 1].split('?');
    const decodedSecrets = window.atob(encodedSecrets.slice(1));

    if (queryString) {
      return getInfoFromUrl(
        `${decodedSecrets}${`&${queryString}`}`,
        queryKeys,
        {
          type: punchoutType,
        }
      );
    }

    return getInfoFromUrl(`${decodedSecrets}`, queryKeys, {
      type: punchoutType,
    });
  }

  const decodedQueryString = window.atob(pathParts[pathParts.length - 1]);
  const [, queryString] = decodedQueryString.split('?');
  return getInfoFromUrl(queryString, queryKeys, { type: 'cxml' });
};

const punchoutData = getPunchoutData();

if (hasAllPropertiesValid(punchoutData)) {
  const { accessKey, secret, type } = punchoutData;

  if (type === 'oci') {
    setCookie({
      name: 'HOOK_URL',
      value: decodeURIComponent(punchoutData?.HOOK_URL),
      expires: new Date().getTime() + SESSION_BASED_EXPIRY_TIME,
    });
  } else {
    setCookie({
      name: 'PUNCHOUT_SESSION_ID',
      value: decodeURIComponent(punchoutData?.sessionId),
      expires: new Date().getTime() + SESSION_BASED_EXPIRY_TIME,
    });
  }

  const credential = new URLSearchParams({
    username: accessKey,
    password: secret,
  });

  const encodedCredential = window.btoa(credential.toString());

  window.location.replace(
    `/technical-login/${type}/${encodedCredential[0]}${encodedCredential}`
  );
}

ReactDOM.render(
  <>
    <ExternalFileScripts />
    <React.StrictMode>
      <ErrorBoundary>
        <Provider store={store}>
          <React.Suspense fallback={<B2becSpinner isLoading />}>
            <ReactRouter history={history}>
              <App />
            </ReactRouter>
          </React.Suspense>
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>
  </>,
  document.getElementById('root')
);
