import './styles.scss';

import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const DescriptionBox = ({ categoryName, text, isLoading }) => (
  <div className="full-width-page-component__wrapper" style={{ width: `calc(100vw - var(--scrollbar-width))` }}>
    <div className="sub-category-description-box__wrapper">
      <div className="sub-category-description-box__content-wrapper">
        {isLoading ? (
          <Skeleton active />
        ) : (
          <div className="fadeIn-0.6">
            <h1 className="sub-category-description-box__heading">
              {categoryName}
            </h1>
            <p className="sub-category-description-box__description">{text}</p>
          </div>
        )}
      </div>
    </div>
  </div>
);

DescriptionBox.propTypes = {
  categoryName: PropTypes.string,
  text: PropTypes.string,
  isLoading: PropTypes.bool,
};

DescriptionBox.defaultProps = {
  categoryName: '',
  text: '',
  isLoading: false,
};

export default DescriptionBox;
